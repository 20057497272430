import Link from "next/link";
import { FaArrowRightLong } from "react-icons/fa6";
import { Image } from "@nextui-org/react";

interface EmptyCartProps {
  onClose: () => void;
}

const EmptyCart: React.FC<EmptyCartProps> = ({ onClose }) => (
  <div className="flex items-center flex-col gap-4">
    <div className="size-80">
      <Image src="/empty-cart.png" radius="none" alt="Empty" />
    </div>
    <p className="font-bold text-xl">Your Shopping Cart is Empty</p>
    <p className="text-center text-lg">
      Check out all the available products and buy some in the shop
    </p>
    <Link href="/">
      <div
        className="bg-icon flex px-6 py-3 gap-4 text-white rounded-md font-bold items-center "
        onClick={() => onClose()}
      >
        Go Shopping
        <FaArrowRightLong />
      </div>
    </Link>
  </div>
);

export default EmptyCart;
