import request from "@/utils/request";

const getCategories = async () =>
  request({
    method: "GET",
    url: "category/all",
    params: {},
  }).then((response) => response);

const getSubCategories = async () =>
  request({
    method: "GET",
    url: "category/subcateory/all",
    params: {},
  }).then((response) => response);

const getCategoryById = async (id: number) =>
  request({
    method: "GET",
    url: `category/${id} `,
    params: {},
  }).then((response) => response);

const getCategoryBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `category/slug/${slug}`,
    params: {},
  }).then((response) => response);

const getSalesCategoryBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `category/slug/${slug}/sales`,
    params: {},
  }).then((response) => response);

const getNewCategoryBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `category/slug/${slug}/new`,
    params: {},
  }).then((response) => response);

const getCategoryTitleBySlug = async (slug: string) =>
  request({
    method: "GET",
    url: `category/slug/${slug}/title`,
    params: {},
  }).then((response) => response);

export {
  getCategories,
  getSubCategories,
  getCategoryById,
  getCategoryBySlug,
  getCategoryTitleBySlug,
  getSalesCategoryBySlug,
  getNewCategoryBySlug,
};
