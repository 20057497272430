import Link from "next/link";
import navList from "@/data/nav";
import { Drawer } from "antd";
import { BsFacebook } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { categoriesList, fetchCategories } from "@/store/slices/categorySlice";
import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { FaPlus } from "react-icons/fa";
import gsap from "gsap";

interface MenuDrawerProps {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
}

const MenuDrawer: React.FC<MenuDrawerProps> = (props) => {
  const { menuOpen, setMenuOpen } = props;
  const dispatch = useAppDispatch();
  const category = useAppSelector(categoriesList);
  const [showId, setShowId] = useState(0);
  const ulRefs = useRef<{ [key: number]: HTMLUListElement | null }>({});
  useEffect(() => {
    if (!isEqual(category.status, EStateAsyncStatus.succeeded)) {
      dispatch(fetchCategories());
    }
  }, [category.status, dispatch]);

  useEffect(() => {
    if (showId !== 0) {
      const items: any = ulRefs.current[showId]?.children;
      gsap.fromTo(
        items,
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.1,
          ease: "power2.out",
        },
      );
    }
  }, [showId]);

  const onClose = () => {
    setShowId(0);
    setMenuOpen(false);
  };

  const date = new Date();

  return (
    <Drawer placement="left" width={480} open={menuOpen} onClose={onClose}>
      <div className="h-full flex flex-col justify-between p-6">
        <nav className="px-4">
          <ul className="flex flex-col gap-2">
            {isEqual(category.status, EStateAsyncStatus.succeeded) &&
              (Array.isArray(category.categoriesList)
                ? category.categoriesList.map((item) => {
                    const { id, name, children, slug, published } = item;
                    const link = `/category/${slug}`;
                    if (!published) return null;
                    return (
                      <div className="relative w-56" key={id}>
                        <div className="group w-56">
                          <Link
                            href={link}
                            onMouseEnter={() => setShowId(id)}
                            onClick={onClose}
                          >
                            <li className="w-56 flex items-center justify-between">
                              <span
                                className={`${
                                  isEqual(showId, id) && "text-icon"
                                } group-hover:text-icon transition-all duration-500`}
                              >
                                {name}
                              </span>
                              <FaPlus
                                className={`${
                                  isEqual(showId, id) && "text-icon rotate-45"
                                } hidden sm:block group-hover:rotate-45 group-hover:text-icon text-base transition-all duration-500`}
                              />
                            </li>
                          </Link>
                        </div>
                        <div className="hidden sm:block">
                          <ul
                            ref={(el) => {
                              if (el) ulRefs.current[id] = el;
                            }}
                            className={`absolute z-40 flex-col gap-2 left-64 top-0 ${
                              isEqual(showId, id) ? "block " : "hidden"
                            } transition-all duration-500`}
                          >
                            {children.map((item) => {
                              const link = `/category/${item.slug}/product`;
                              return (
                                <Link
                                  key={item.id}
                                  onClick={onClose}
                                  href={link}
                                  className="hover:text-icon w-56 flex items-center justify-between transition-all duration-500"
                                >
                                  {item.name}
                                </Link>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })
                : navList.map(({ label, link }, index) => (
                    <Link key={index} href={link}>
                      <li className="hover:text-icon hover:border-icon transition-all duration-500">
                        {label}
                      </li>
                    </Link>
                  )))}
            <Link
              href="/sale"
              onMouseEnter={() => setShowId(0)}
              onClick={onClose}
            >
              <li className="w-56 flex items-center justify-between">
                <span className="text-red-500">Sale</span>
              </li>
            </Link>
          </ul>
        </nav>
        <div className="flex flex-col gap-2">
          <p className="text-gray-600">
            ©{date.getFullYear()} Priceworth Furniture. All rights reserved
          </p>
          <nav>
            <ul className="flex list-none">
              <Link
                href="/privacy-notice"
                onClick={onClose}
                className="hover:text-icon"
              >
                <li className="pr-2">Privacy Notice</li>
              </Link>
              <Link
                href="/shopping-faqs"
                onClick={onClose}
                className="hover:text-icon"
              >
                <li className="px-2 border-x">Shopping FAQs</li>
              </Link>
              <Link
                href="/returns-refunds"
                onClick={onClose}
                className="hover:text-icon"
              >
                <li className="pl-2">Returns & Refunds</li>
              </Link>
            </ul>
          </nav>
          <div className="flex gap-2 items-center">
            <Link
              href="https://www.facebook.com/www.priceworth.com.au/"
              onClick={onClose}
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook className="text-3xl hover:text-icon transition-all duration-500" />
            </Link>
            <Link
              href="https://www.instagram.com/priceworthfurniture.vic/"
              onClick={onClose}
              target="_blank"
              rel="noreferrer"
            >
              <RiInstagramFill className="text-3xl hover:text-icon transition-all duration-500" />
            </Link>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
