import Link from "next/link";
import { Image } from "@nextui-org/react";
import dayjs from "dayjs";
import { ICart, ICartItem } from "@/interfaces/cart";
import { LiaTimesSolid } from "react-icons/lia";
import { formatPrice } from "@/utils/format";
import { FaTimes } from "react-icons/fa";
import { useAppDispatch } from "@/hooks/useRedux";
import { fetchDeleteCart } from "@/store/slices/cartSlice";
import { isEmpty } from "lodash";
import { Rating, Star } from "@smastrom/react-rating";

interface CartItemProps {
  item: ICartItem;
  cart: ICart | null;
}

const CartItem: React.FC<CartItemProps> = (props) => {
  const { item, cart } = props;
  const { quantity, productVariant, isAssembly } = item;
  const dispatch = useAppDispatch();

  const handleDelete = (id: number) => {
    if (cart) {
      const payload = {
        productVariantId: id,
        shoppingCartId: cart.id,
      };
      dispatch(fetchDeleteCart(payload));
    }
  };

  return (
    <div className="flex gap-6 w-full justify-between">
      <div className="flex gap-4">
        <Link href={`/product/${productVariant.product.slug}`}>
          <div className="size-36 flex items-center justify-center">
            <Image
              src={productVariant.images[0]}
              alt={productVariant.name}
              radius="none"
            />
          </div>
        </Link>
        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <p className="text-icon">{productVariant.product.model}</p>
            {!isEmpty(productVariant.reviews) && (
              <div className="flex gap-2">
                <Rating
                  readOnly
                  value={
                    productVariant.reviews.reduce(
                      (sum, review) => sum + review.rate,
                      0,
                    ) / productVariant.reviews.length
                  }
                  style={{ maxWidth: 80 }}
                  itemStyles={{
                    itemShapes: Star,
                    activeFillColor: "#B79B6C",
                    inactiveFillColor: "#FFEACF",
                  }}
                />
                <p className="text-gray-400">
                  ({productVariant.reviews.length})
                </p>
              </div>
            )}
          </div>
          <Link href={`/product/${productVariant.product.slug}`}>
            <h3 className="hover:text-icon transition-all duration-500 text-lg">
              {productVariant.name}
            </h3>
          </Link>
          <p>
            Delivery Estimated: Within{" "}
            {`${dayjs().add(2, "day").format("MMM DD")} - ${dayjs()
              .add(9, "day")
              .format("MMM DD")}`}
          </p>
          <p className="flex items-center gap-1 mt-1">
            {quantity}
            <LiaTimesSolid />
            <span className="text-icon">
              {formatPrice(productVariant.price)}
            </span>
            {isAssembly && (
              <span> + {formatPrice(productVariant.assemblyFee)}</span>
            )}
          </p>
        </div>
      </div>
      <FaTimes
        className="text-icon text-xl cursor-pointer"
        onClick={() => handleDelete(productVariant.id)}
      />
    </div>
  );
};

export default CartItem;
