import { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { customer, fetchCustomer } from "@/store/slices/customerSlice";
import {
  get as storeGet,
  put as storePut,
  del as storeDel,
} from "@/utils/storageHelper";
import { CARTID, TOKEN } from "@/constants/storeLocation";
import { createGuestCart } from "@/service/cart";
import { isEmpty, isEqual } from "lodash";
import { notify } from "@/components/Shares/Notification";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { login, loginInfo, signOut } from "@/store/slices/loginSlice";
import { cart, fetchCart } from "@/store/slices/cartSlice";
import CartDrawer from "../CartDrawer";
import MenuDrawer from "../MenuDrawer";
import SearchDrawer from "../SearchDrawer";
import BackToTop from "../BackToTop";
import RecentPurchase from "../RecentPurchase";
import Discount from "../Discount";

interface LayoutWrapperProps {
  children: React.ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const dispatch = useAppDispatch();
  const customerState = useAppSelector(customer);
  const loginState = useAppSelector(loginInfo);
  const cartState = useAppSelector(cart);

  useEffect(() => {
    const token = storeGet(TOKEN);
    if (!isEmpty(token)) {
      dispatch(login());
    } else {
      dispatch(signOut());
    }
  }, [dispatch]);

  useEffect(() => {
    const createCart = async () => {
      const response = await createGuestCart();
      if (isEqual(response.statusCode, 200)) {
        const newCartId = response.data.id;
        storePut(CARTID, newCartId);
        dispatch(fetchCart(newCartId));
      } else {
        notify(
          "Failed to create a cart. Please refresh the page and try again.",
          "error",
        );
      }
    };

    const fetchAndHandleCart = async (cartId: string) => {
      try {
        const res: any = await dispatch(fetchCart(cartId));
        if (res.payload.statusCode !== 200) {
          // If fetching cart failed, delete cartId and create a new one
          storeDel(CARTID);
          await createCart();
        }
      } catch (error) {
        // Handle unexpected errors
        storeDel(CARTID);
        await createCart();
      }
    };

    if (loginState.isLogin === "pending") return;

    if (!loginState.isLogin) {
      // not login, check cartId in localstorage
      const cartId = storeGet(CARTID);

      if (isEmpty(cartId)) {
        // no cartId, create a new cart
        createCart();
      } else {
        // have cartId, fetch cart information and store in redux
        fetchAndHandleCart(String(cartId));
      }
    } else {
      // if login, get customer Information
      dispatch(fetchCustomer());
      storeDel(CARTID);
    }
  }, [loginState.isLogin, dispatch]);

  useEffect(() => {
    if (
      isEqual(customerState.status, EStateAsyncStatus.succeeded) &&
      customerState.customerInfo
    ) {
      dispatch(fetchCart(customerState.customerInfo.shoppingCartId as string));
    }
  }, [customerState, dispatch]);

  return (
    <>
      <Header
        cart={cartState.cart}
        setMenuOpen={setMenuOpen}
        setSearchOpen={setSearchOpen}
      />
      <CartDrawer cart={cartState.cart} cartOpen={cartState.open} />
      <MenuDrawer menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <SearchDrawer searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      <main
        className={`flex min-h-screen px-2 flex-col items-center container mx-auto`}
      >
        {children}
      </main>
      <Footer />
      <BackToTop />
      <RecentPurchase />
      <Discount />
    </>
  );
};

export default LayoutWrapper;
