import { notify } from "@/components/Shares/Notification";
import { subscribe } from "@/service/subscribe";
import { isValidEmail } from "@/utils/check";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { TfiGift } from "react-icons/tfi";

const Discount = () => {
  const [email, setEmail] = useState("");
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  useEffect(() => {
    // Check if the user has visited the site before
    const hasVisited = localStorage.getItem("hasVisited");

    if (!hasVisited) {
      // If not, set a timeout to show the discount modal after a delay
      const timer = setTimeout(() => {
        onOpen(); // Open the modal
        localStorage.setItem("hasVisited", "true");
      }, 5000); // 5-second delay

      // Clean up the timer if the component unmounts before the timeout finishes
      return () => clearTimeout(timer);
    }
  }, [onOpen]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      notify("Email is invalid", "error");
      return;
    }

    const res = await subscribe(email);

    if (res.statusCode === 200) {
      notify(
        "Thank you for subscription! You will get noticed about our latest news and special offers. A special discount code will be sent to your email.",
        "success",
      );
      onClose();
    } else {
      notify(res.data.message, "error");
    }
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="xl">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex flex-col items-center p-9 gap-8">
                <TfiGift className="text-5xl" />
                <div className="flex flex-col gap-6 items-center text-center">
                  <p className="font-semibold text-3xl">
                    Enjoy $30 off your first order
                  </p>
                  <p className="text-xl">
                    Unlock your exclusive discount by subscribing. Enter your
                    email to get your code.
                  </p>
                </div>
                <div className="flex rounded overflow-hidden border border-gray-300 select-none w-full">
                  <input
                    className="bg-opacity-90 text-base lg:text-lg w-full px-4 py-2 min-w-36 focus:outline-icon"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    className="bg-icon w-16  transition-all duration-500 text-white p-6"
                    onClick={handleSubmit}
                    radius="none"
                  >
                    Go
                  </Button>
                </div>
                <small className="text-center max-w-xs">
                  *Min spend $199 on products (excluding shipping). New
                  subscribers only.
                </small>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Discount;
