import { ICategory } from "@/interfaces/category";
import { isEqual } from "lodash";
import Link from "next/link";
import SubMenuSection from "../SubMenuSection/SubMenuSection";

interface MenuItemProps {
  category: ICategory;
  showId?: number;
}

const MenuItemList = [
  { image: "/new-in.webp", name: "New In", link: "new-in" },
  { image: "/bestseller.webp", name: "BestSellers", link: "bestseller" },
  { image: "/sales.webp", name: "Sales", link: "sales" },
];

const MenuItem: React.FC<MenuItemProps> = ({ category, showId }) => {
  const { id, children } = category;
  const isVisible = isEqual(showId, id);

  return (
    <div
      className={`absolute container p-8 bg-white top-[106px] left-1/2 
        transform -translate-x-1/2 shadow-card justify-between transition-all duration-300 
        ease-in-out origin-top delay-300
        ${
          isVisible
            ? "scale-y-100 opacity-100 flex"
            : "scale-y-50 opacity-0 hidden"
        }`}
    >
      <div className="flex-1">
        <h4 className="text-2xl mb-8">{category.name}</h4>
        <ul className="grid grid-cols-2 text-sm gap-3 max-w-lg">
          {children.map((item) => {
            const link = `/category/${item.slug}/product`;
            return (
              <li key={item.id} className="flex-1">
                <Link
                  href={link}
                  className="hover:text-icon w-56 flex items-center justify-between transition-all duration-500"
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="flex flex-1 gap-5">
        {MenuItemList.map((item, index) => {
          const { image, link, name } = item;
          return (
            <SubMenuSection
              key={index}
              image={`/${category.slug}${image}`}
              link={link}
              name={name}
              slug={category.slug}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MenuItem;
