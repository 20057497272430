import EStateAsyncStatus from "@/constants/stateAsyncState";
import { AppState } from "@/interfaces/redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addItemToCart,
  deleteItemFromCart,
  emptyCart,
  getCartById,
  updateItemFromCart,
} from "@/service/cart";
import {
  AddItemToCartPayload,
  DeleteItemFromCartPayload,
  ICart,
  UpdateItemFromCartPayload,
} from "@/interfaces/cart";

export interface IInitialState {
  cart: ICart | null;
  status:
    | EStateAsyncStatus.failed
    | EStateAsyncStatus.idle
    | EStateAsyncStatus.pending
    | EStateAsyncStatus.succeeded;
  open: boolean;
}

const initialState: IInitialState = {
  cart: null,
  status: EStateAsyncStatus.idle,
  open: false,
};

const fetchCart = createAsyncThunk("cart/create", async (id: string) => {
  const res = getCartById(id);
  return res;
});

const fetchAddCart = createAsyncThunk(
  "cart/add",
  async (payload: AddItemToCartPayload) => {
    const res = addItemToCart(payload);
    return res;
  },
);

const fetchDeleteCart = createAsyncThunk(
  "cart/delete",
  async (payload: DeleteItemFromCartPayload) => {
    const res = deleteItemFromCart(payload);
    return res;
  },
);

const fetchUpdateCart = createAsyncThunk(
  "cart/update",
  async (payload: UpdateItemFromCartPayload) => {
    const res = updateItemFromCart(payload);
    return res;
  },
);

const fetchEmptyCart = createAsyncThunk(
  "cart/empty",
  async (cartId: string) => {
    const res = emptyCart(cartId);
    return res;
  },
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    openCart(state) {
      state.open = true;
    },
    closeCart(state) {
      state.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.cart = action.payload.data;
      })
      .addCase(fetchCart.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });

    builder
      .addCase(fetchAddCart.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchAddCart.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.cart = action.payload.data;
      })
      .addCase(fetchAddCart.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });

    builder
      .addCase(fetchDeleteCart.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchDeleteCart.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.cart = action.payload.data;
      })
      .addCase(fetchDeleteCart.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });

    builder
      .addCase(fetchUpdateCart.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchUpdateCart.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.cart = action.payload.data;
      })
      .addCase(fetchUpdateCart.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });

    builder
      .addCase(fetchEmptyCart.pending, (state) => {
        state.status = EStateAsyncStatus.pending;
      })
      .addCase(fetchEmptyCart.fulfilled, (state, action) => {
        state.status = EStateAsyncStatus.succeeded;
        state.cart = action.payload.data;
      })
      .addCase(fetchEmptyCart.rejected, (state) => {
        state.status = EStateAsyncStatus.failed;
      });
  },
});

export {
  fetchCart,
  fetchAddCart,
  fetchDeleteCart,
  fetchUpdateCart,
  fetchEmptyCart,
};
export const { openCart, closeCart } = cartSlice.actions;
export const cart = (state: AppState) => state.cart;
export default cartSlice.reducer;
