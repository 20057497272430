import { ICart } from "@/interfaces/cart";
import { isEmpty } from "lodash";
import { Drawer } from "antd";
import { Button } from "@nextui-org/react";
import EmptyCart from "@/components/Shares/EmptyCart";
import { closeCart } from "@/store/slices/cartSlice";
import { useAppDispatch } from "@/hooks/useRedux";
import { formatPrice } from "@/utils/format";
import { FcApproval } from "react-icons/fc";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import CartItem from "@/components/Shares/CartItem";

interface CartDrawerProps {
  cart: ICart | null;
  cartOpen: boolean;
}

const CartDrawer: React.FC<CartDrawerProps> = (props) => {
  const { cart, cartOpen } = props;
  const dispatch = useAppDispatch();
  const [drawerWidth, setDrawerWidth] = useState(574); // Default max width
  const router = useRouter();

  useEffect(() => {
    const updateWidth = () => {
      setDrawerWidth(Math.min(window.innerWidth * 0.9, 574));
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const onClose = () => {
    dispatch(closeCart());
  };

  return (
    <Drawer
      title={
        <div className="flex items-center gap-1">
          <FcApproval className="text-3xl" />
          <h2 className="text-2xl">
            Great pick, {`we've`} added it to your cart
          </h2>
        </div>
      }
      placement="right"
      width={drawerWidth}
      open={cartOpen}
      closable={false}
      onClose={onClose}
    >
      <div className="h-full">
        <div className="p-6">
          {cart && !isEmpty(cart.shoppingCartItems) ? (
            <div className="h-full flex flex-col">
              <div className="flex flex-col gap-4">
                {cart.shoppingCartItems.map((item) => (
                  <CartItem key={item.id} item={item} cart={cart} />
                ))}
              </div>
              <div className="border-stroke border-t py-4 mt-14">
                <div className="flex justify-between text-lg">
                  <span>Total:</span> <span>{formatPrice(cart.total)}</span>
                </div>
                <Button
                  className="select-none bg-icon w-full mt-4 text-lg text-white rounded-md h-14 flex items-center justify-center"
                  onClick={() => {
                    dispatch(closeCart());
                    router.push("/checkout");
                  }}
                >
                  View Cart
                </Button>
                <Button
                  className="select-none cursor-pointer bg-white border border-icon w-full mt-4 h-14 text-lg text-icon rounded-md flex items-center justify-center"
                  onClick={() => dispatch(closeCart())}
                >
                  Continue Shopping
                </Button>
              </div>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <EmptyCart onClose={onClose} />
            </div>
          )}
        </div>
        {/* <div className="border-t border-icon p-6">
          <h3 className="text-xl">You might also like</h3>
        </div> */}
      </div>
    </Drawer>
  );
};

export default CartDrawer;
