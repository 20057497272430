import { Image } from "@nextui-org/react";
import React from "react";
import Link from "next/link";
import { IoIosArrowRoundForward } from "react-icons/io";

interface SubMenuSectionProps {
  image: string;
  name: string;
  link: string;
  slug: string;
}

const SubMenuSection: React.FC<SubMenuSectionProps> = (props) => {
  const { image, name, link, slug } = props;
  const completedLink = `/category/${slug}/${link}`;
  return (
    <Link href={completedLink} className="group">
      <div className="border-icon border group-hover:border-priceworth">
        <Image alt={name} src={image} radius="none" />
        <div className="border-t border-icon group-hover:border-priceworth group-hover:bg-priceworth group-hover:text-white text-icon flex items-center justify-between p-3">
          <span>{name}</span>
          <IoIosArrowRoundForward className="text-3xl" />
        </div>
      </div>
    </Link>
  );
};

export default SubMenuSection;
